export const dataURLtoBlob = (dataUrl) => {
    var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export const fileToDataURL = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject(error);
        };
    });
};

export const getImageSizeUrls = (url, containerElement) => {
    const match = url?.match(/(http.*\/.*)\/(.*)/);

    if (!url || !match || match?.length < 3 || url?.startsWith("blob:")) {
        return { thumbUrl: url, displayUrl: url };
    }

    const hostName = match[1];
    const fileName = match[2];

    const frameSizes = containerElement.getBoundingClientRect();
    const width = Math.round(frameSizes.width);
    const height = Math.round(frameSizes.height);

    const imageResizePath = getResizedImagePath({ width, height });
    const displayResizePath = getResizedImagePath({ width: window.innerWidth, height: window.innerHeight });

    return {
        thumbUrl: `${hostName}/${imageResizePath}/${fileName}`,
        displayUrl: `${hostName}/${displayResizePath}/${fileName}`
    };
};

export const getResizedImagePath = ({ width, height }) => {
    const value = Math.max(width, height);
    if (value <= 150) {
        return `resized/150`;
    } else if (value <= 450) {
        return `resized/450`;
    } else if (value <= 750) {
        return `resized/750`;
    } else {
        return "resized/1500";
    }
};

export const toPrintableFileSize = (sizeInBytes) => {
    if (sizeInBytes === 0) return "0 Bytes";
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    return `${(sizeInBytes / Math.pow(1024, i)).toFixed(2)} ${["Bytes", "KB", "MB", "GB", "TB"][i]}`;
};
